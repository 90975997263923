import React, { useEffect, useState } from 'react';
import { SimpleGrid, Box, Text, Select, Button } from '@chakra-ui/react';
import { useColorModeValue } from '@chakra-ui/color-mode';
import axios from 'axios';

const CalculetteArgentSale = () => {
    const [argentSale, setArgentSale] = useState(0);
    const [taux, setTaux] = useState(30);
    const [resultat, setResultat] = useState(0);

    useEffect(() => {
        // Récupérer l'argent sale depuis l'API
        const fetchArgentSale = async () => {
            try {
                const response = await axios.get('https://peaky.api.mrapi.net/api/money');
                const dirtyMoney = response.data.find(item => item.name === 'Argent Sale');
                if (dirtyMoney) {
                    setArgentSale(dirtyMoney.stock);
                }
            } catch (error) {
                console.error('Erreur lors de la récupération de l\'argent sale:', error);
            }
        };

        fetchArgentSale();
    }, []);

    useEffect(() => {
        // Calculer le résultat après déduction du taux
        const calculerResultat = () => {
            const tauxDecimal = taux / 100;
            const montantApresTaux = argentSale - (argentSale * tauxDecimal);
            setResultat(montantApresTaux);
        };

        calculerResultat();
    }, [taux, argentSale]);

    return (
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} p={10}>
            <Box
                bg={useColorModeValue('white', 'gray.700')}
                boxShadow="lg"
                rounded="lg"
                p={8}
                textAlign="center"
            >
                <Text fontSize="2xl" fontWeight="bold" mb={4}>
                    Argent Sale: {argentSale} $
                </Text>
                <Select
                    value={taux}
                    onChange={(e) => setTaux(Number(e.target.value))}
                    mb={4}
                >
                    <option value={25}>25%</option>
                    <option value={30}>30%</option>
                    <option value={35}>35%</option>
                    <option value={40}>40%</option>
                    <option value={45}>45%</option>
                </Select>
                <Text fontSize="2xl" fontWeight="bold" mt={4}>
                    Résultat après déduction: {resultat.toFixed(0)} $
                </Text>
            </Box>
        </SimpleGrid>
    );
};

export default CalculetteArgentSale;
