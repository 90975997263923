// Chakra imports
import {
    Flex,
    Grid,
    Box,
    Image,
    SimpleGrid,
    useColorModeValue,
  } from "@chakra-ui/react";
  // assets
  import peopleImage from "assets/img/people-image.png";
  import logoChakra from "assets/svg/logo-white.svg";
  import BarChart from "components/Charts/BarChart";
  import LineChart from "components/Charts/LineChart";

  //Custom

import StatsArgent from "./components/StatsArgent";
  // Custom icons
  import {
    CartIcon,
    DocumentIcon,
    GlobeIcon,
    WalletIcon,
  } from "components/Icons/Icons.js";
  import React from "react";
  import { dashboardTableData, timelineData } from "variables/general";
  import ActiveUsers from "./components/ActiveUsers";
  import BuiltByDevelopers from "./components/BuiltByDevelopers";
  import MiniStatistics from "./components/MiniStatistics";
  import TimeLineItem from "./components/TimeLineItem";
  import Projects from "./components/Projects";
  import SalesOverview from "./components/SalesOverview";
  import WorkWithTheRockets from "./components/WorkWithTheRockets";
import CalculetteArgentSale from "./components/CalculetteArgentSale";
import CalculatriceAS from "./components/CalculatriceAS";
import UserOnlineServer from "./components/UserOnlineServer";
import StatusComponent from "./components/StatusCFXRE";
  
  export default function Dashboard() {
    const iconBoxInside = useColorModeValue("white", "white");
  
    return (
      <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <StatsArgent />
      <Flex>
        <Box flex="1" mr={1}>
          <CalculetteArgentSale />
          <CalculatriceAS />
        </Box>
        <Box flex="0 0 auto">
          <UserOnlineServer />
        </Box>
        <Box mr={1}>
        <StatusComponent />
      </Box>
      </Flex>
    </Flex>
  );
}