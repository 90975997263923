import React, { useEffect, useState } from 'react';
import { SimpleGrid, Box, Text, Flex, Image } from '@chakra-ui/react';
import { useColorModeValue } from '@chakra-ui/color-mode';
import axios from 'axios';
import Argent from "assets/imgdrop/money.png";
import Passport from "assets/imgdrop/passport.png";
import Medaille from "assets/imgdrop/medaille.png";

const MiniStatistics = ({ title, amount, icon }) => {
  const iconBoxInside = useColorModeValue('white', 'gray.800');

  return (
    <Box
      p={4}
      bg={useColorModeValue('white', 'gray.700')}
      shadow='md'
      borderRadius='lg'
    >
      <Flex justify='space-between' align='center'>
        <Box>
          <Text fontSize='lg' fontWeight='bold'>{title}</Text>
          <Text fontSize='2xl' fontWeight='bold'>{amount}</Text>
        </Box>
        <Box bg={iconBoxInside} borderRadius='full' p={2}>
          <Image src={icon} h="24px" w="24px" />
        </Box>
      </Flex>
    </Box>
  );
};

const Dashboard = () => {
  const [data, setData] = useState({
    argentPropre: 0,
    argentSale: 0,
    totalArgent: 0,
    nbPersonnePF: 0,
    nbpoint: '0'  // Default value
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch data from the money API
        const moneyResponse = await axios.get('https://peaky.api.mrapi.net/api/money');
        const moneyItems = moneyResponse.data;
        
        // Calculate the sums
        const argentPropre = moneyItems
          .filter(item => item.type === 'money')
          .reduce((total, item) => total + item.stock, 0);

        const argentSale = moneyItems
          .filter(item => item.type === 'dirty_money')
          .reduce((total, item) => total + item.stock, 0);

        const totalArgent = argentPropre + argentSale;

        // Fetch data from the organization API
        const orgResponse = await axios.get('https://peaky.api.mrapi.net/api/Orga');
        const orgItems = orgResponse.data;

        // Assuming you want to display data for the first organization
        if (orgItems.length > 0) {
          const org = orgItems[0];
          setData({ 
            argentPropre, 
            argentSale, 
            totalArgent,
            nbPersonnePF: org.membre,
            nbpoint: `#${org.classement} - ${org.pts} Pts`
          });
        }
      } catch (error) {
        console.error('There was an error fetching the data!', error);
      }
    };

    fetchData();
  }, []);

  return (
    <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing='24px'>
      <MiniStatistics
        title={"Argent Propre"}
        amount={`${data.argentPropre}$`}
        icon={Argent}
      />
      <MiniStatistics
        title={"Argent Sale"}
        amount={`${data.argentSale}$`}
        icon={Argent}
      />
      <MiniStatistics
        title={"NB Personne PF"}
        amount={data.nbPersonnePF}
        icon={Passport}
      />
      <MiniStatistics
        title={"Classement PF"}
        amount={data.nbpoint}
        icon={Medaille}
      />
    </SimpleGrid>
  );
};

export default Dashboard;
