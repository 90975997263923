import React, { useState, useEffect } from 'react';
import { Box, Heading, Text, VStack, Spinner } from '@chakra-ui/react';
import axios from 'axios';

function DMList() {
    const [dms, setDms] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchDms = async () => {
            try {
                const response = await axios.get('http://mariabird.net:65535/api/dms');
                setDms(response.data);
            } catch (error) {
                console.error('Error fetching DMs:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchDms();
    }, []);

    if (loading) {
        return <Spinner size="xl" />;
    }

    return (
        <Box p={4}>
            <Heading mb={4}>Direct Messages</Heading>
            <VStack spacing={4} align="start">
                {dms.map((dm) => (
                    <Box key={dm.id} p={4} shadow="md" borderWidth="1px">
                        <Text fontWeight="bold">{dm.username} ({dm.user_id})</Text>
                        <Text>{dm.message_content}</Text>
                        <Text color="gray.500" fontSize="sm">{new Date(dm.timestamp).toLocaleString()}</Text>
                    </Box>
                ))}
            </VStack>
        </Box>
    );
}

export default DMList;
