import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { SimpleGrid } from '@chakra-ui/react';
import DrugCard from './DrugCard'; // Assurez-vous que le chemin est correct

const drugsList = [
  'Cocaïne',
  'Crack',
  'Tranq',
  'Weed',
  'Opium',
  'Purple',
  'Ecstasy',
  'Heroïne',
  'Méthanphétamine'
];

const VenteDrogue = () => {
  const [drugsData, setDrugsData] = useState({});

  useEffect(() => {
    const fetchDrugsData = async () => {
      try {
        const responses = await Promise.all(
          drugsList.map(drug => 
            axios.get(`https://yourapi.example.com/api/drugs/${encodeURIComponent(drug)}`)
          )
        );
        const drugsData = responses.reduce((acc, response, index) => {
          acc[drugsList[index]] = response.data;
          return acc;
        }, {});
        setDrugsData(drugsData);
      } catch (error) {
        console.error('Error fetching drugs data:', error);
      }
    };

    fetchDrugsData();
  }, []);

  return (
    <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} spacing='24px'>
      {drugsList.map(drug => (
        <DrugCard
          key={drug}
          drugName={drug}
          quantity={drugsData[drug]?.quantity || 0}
          membersSold={drugsData[drug]?.membersSold || 0}
        />
      ))}
    </SimpleGrid>
  );
};

export default VenteDrogue;
