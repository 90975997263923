import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Box,
    Flex,
    Heading,
    Text,
    Icon,
    Image,
    Badge,
    useColorModeValue,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Select,
} from '@chakra-ui/react';
import { ArrowForwardIcon, ArrowBackIcon, InfoIcon } from '@chakra-ui/icons';

const WELCOME_MEMBERS_API_URL = 'https://peaky.api.mrapi.net/api/welcome-members';
const ALL_USERS_API_URL = 'https://peaky.bot.mrapi.net/api/all-users';
const ROLES_API_URL = 'https://peaky.bot.mrapi.net/api/roles';

// Fonction pour générer une couleur hexadécimale basée sur le nom du grade
const generateColorFromString = (string) => {
    let hash = 0;
    for (let i = 0; i < string.length; i++) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xFF;
        color += ('00' + value.toString(16)).substr(-2);
    }
    return color;
};

const MemberCardlog = () => {
    const [welcomeMembers, setWelcomeMembers] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [roles, setRoles] = useState([]);
    const [selectedMember, setSelectedMember] = useState(null);
    const [lastUpdate, setLastUpdate] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const fetchMembersData = () => {
        axios.get(WELCOME_MEMBERS_API_URL)
            .then(response => {
                setWelcomeMembers(response.data);
                setLastUpdate(new Date());
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des membres de bienvenue:', error);
            });

        axios.get(ALL_USERS_API_URL)
            .then(response => {
                setAllUsers(response.data);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des utilisateurs:', error);
            });

        axios.get(ROLES_API_URL)
            .then(response => {
                setRoles(response.data);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des rôles:', error);
            });
    };

    useEffect(() => {
        fetchMembersData();
        const intervalId = setInterval(fetchMembersData, 10000);
        return () => clearInterval(intervalId);
    }, []);

    const handleOpenModal = (member) => {
        setSelectedMember(member);
        onOpen();
    };

    const filteredMembers = welcomeMembers.map(welcomeMember => {
        const userWithGrade = allUsers.find(user => user.user_id === welcomeMember.user_id);
        return {
            ...welcomeMember,
            grade: userWithGrade && welcomeMember.is_arrival ? userWithGrade.grade : null, // Ne pas afficher le grade si l'utilisateur a quitté
        };
    });

    const totalArrivals = welcomeMembers.filter(member => member.is_arrival).length;
    const totalDepartures = welcomeMembers.filter(member => !member.is_arrival).length

    return (
        <Flex flexDirection='column' pt={{ base: "120px", md: "75px" }}>
            <Flex justifyContent="flex-end" mb="10px">
                <Flex alignItems="center" mr={4}>
                    <Text fontSize="sm" color="green.500" mr={2}>
                        A : {totalArrivals}
                    </Text>
                    <Text fontSize="sm" color="red.500" mr={4}>
                        D : {totalDepartures}
                    </Text>
                </Flex>
                {lastUpdate && (
                    <Text fontSize="sm" color="blue.200">
                    Mise à jour à : {lastUpdate.toLocaleTimeString()}
                    </Text>
                )}
            </Flex>
            <Flex wrap="wrap" gap="4" justify="center">
                {filteredMembers.map(member => (
                    <Box 
                        key={member.user_id} 
                        maxW="sm" 
                        borderWidth="1px" 
                        borderRadius="md" 
                        overflow="hidden"
                        p={4}
                        boxShadow="md"
                        bg={useColorModeValue('white', 'gray.800')}
                        position="relative"
                    >
                        <Button 
                            position="absolute" 
                            top="1px" 
                            right="183px" 
                            onClick={() => handleOpenModal(member)}
                            size="sm"
                            bg="transparent"
                            color="gray.500"
                            _hover={{ bg: 'transparent' }}
                        >
                            <InfoIcon />
                        </Button>
                        <Flex justify="center" mb={4}>
                            <Image 
                                boxSize="150px" 
                                src={member.avatar_url} 
                                alt={member.username} 
                                objectFit="cover"
                            />
                        </Flex>
                        <Heading size="md" mb="2" textAlign="center">{member.username}</Heading>
                        <Text fontSize="sm" color="gray.500" textAlign="center">
                            Nous à Rejoint le: {new Date(member.join_date).toLocaleDateString()}
                        </Text>
                        {!member.is_arrival && member.leave_date && (
                            <Text fontSize="sm" color="gray.500" textAlign="center">
                                Nous à Quitter le: {new Date(member.leave_date).toLocaleDateString()}
                            </Text>
                        )}
                        <Flex justify="center" mt={4} direction="column" align="center">
                            {member.grade && (
                                <Badge 
                                    p={2} 
                                    borderRadius="md" 
                                    mb={2}
                                    fontSize="sm"
                                    variant="outline"
                                    style={{ 
                                        borderColor: generateColorFromString(member.grade), 
                                        color: generateColorFromString(member.grade)
                                    }}
                                >
                                    {member.grade}
                                </Badge>
                            )}
                            {member.is_arrival ? (
                                <Icon as={ArrowForwardIcon} color="green.500" w={8} h={8} />
                            ) : (
                                <Icon as={ArrowBackIcon} color="red.500" w={8} h={8} />
                            )}
                        </Flex>
                    </Box>
                ))}
            </Flex>

            {selectedMember && (
                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>{selectedMember.username}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Text><strong>ID :</strong> {selectedMember.user_id}</Text>
                            <Text><strong>Grade :</strong> {selectedMember.grade}</Text>
                            <Text><strong>Date de rejoint :</strong> {new Date(selectedMember.join_date).toLocaleDateString()}</Text>
                            {selectedMember.leave_date && (
                                <Text><strong>Date de départ :</strong> {new Date(selectedMember.leave_date).toLocaleDateString()}</Text>
                            )}
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="ghost" onClick={onClose}>Fermer</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            )}
        </Flex>
    );
};

export default MemberCardlog;
