import React, { useState, useEffect, useRef } from 'react';
import { Box, Heading, List, ListItem, Text, Flex, Avatar, Button, IconButton, Tooltip } from '@chakra-ui/react';
import { useDisclosure, Checkbox } from '@chakra-ui/react';
import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/menu';
import { FaMicrophone, FaMicrophoneSlash, FaBan, FaVideo, FaHeadphones } from 'react-icons/fa';
import { TbHeadphonesOff } from "react-icons/tb";
import { CiStreamOn } from "react-icons/ci";
import { MdOutlineLiveTv } from "react-icons/md";
import axios from 'axios';
import io from 'socket.io-client';
import UserMenu from './components/UserMenu';
//import UserMenu2 from './components/test';
import { FaCircle } from 'react-icons/fa';
import LogsRTCBot from './components/LogsRTCBot';

const VoiceChannelList = () => {
    const [channels, setChannels] = useState([]);
    const [selectedChannel, setSelectedChannel] = useState(null);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [micMuted, setMicMuted] = useState(true);
    const [audioMuted, setAudioMuted] = useState(false);
    const [logs, setLogs] = useState('');
    const [showLogs, setShowLogs] = useState(false);
    const mediaStreamRef = useRef(null);
    const audioContextRef = useRef(null);
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
    const [menuOpen, setMenuOpen] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    //const [botPing, setBotPing] = useState(null);
    const [botInfo, setBotInfo] = useState(() => {
        // Retrieve bot info from localStorage on page load
        const savedBotInfo = localStorage.getItem('botInfo');
        return savedBotInfo ? JSON.parse(savedBotInfo) : { username: '', latency: 0 };
    });

    // Fetch voice channels and update every 2 seconds
    useEffect(() => {
        const fetchChannels = () => {
            axios.get('http://51.158.60.90:65533/api/voice')
                .then(response => setChannels(response.data))
                .catch(error => console.error(error));
        };

        fetchChannels(); // Initial fetch
        const intervalId = setInterval(fetchChannels, 2000); // Fetch channels every 2 seconds

        return () => clearInterval(intervalId); // Cleanup on unmount
    }, []);

    // Fetch users when a channel is clicked
    const handleChannelClick = (channelId) => {
        axios.post(`http://51.158.60.90:65533/api/join`, { channelId })
            .then(() => {
                setSelectedChannel(channelId);
                console.log('Bot joined channel');
            })
            .catch(error => console.error(error));

        // Fetch users in the selected channel
        axios.get(`http://51.158.60.90:65533/api/channel-users?channelId=${selectedChannel}`)
    .then(response => {
        if (Array.isArray(response.data)) {
            setUsers(response.data);  // This assumes the response is an array of users
        } else {
            console.error("Expected an array of users, but received:", response.data);
            setUsers([]);  // Fallback to an empty array if not the expected format
        }
    })
    .catch(error => console.error(error));

    };

    useEffect(() => {
        // Function to fetch users, bot ping, and bot info
        const fetchUsersAndBotInfo = () => {
            // Fetch users and bot ping
            axios.get(`http://51.158.60.90:65533/api/channel-users?channelId=${selectedChannel}`)
                .then(response => {
                    setUsers(response.data.users);  // Update users array
                    setBotInfo(prevState => ({
                        ...prevState,
                        latency: response.data.botPing || 0 // Update bot ping or set to 0 if disconnected
                    }));
                })
                .catch(error => {
                    console.error('Error fetching users and bot ping:', error);
                    setBotInfo(prevState => ({
                        ...prevState,
                        latency: 0 // Set bot ping to 0 in case of an error or disconnection
                    }));
                });
    
            // Fetch bot info
            axios.get('http://51.158.60.90:65533/api/!/secure/application/-/0/-/informations')
                .then(response => {
                    setBotInfo(prevState => ({
                        ...prevState,
                        username: response.data.username, // Update bot username
                    }));
                })
                .catch(error => {
                    console.error('Error fetching bot info:', error);
                });
        };
    
        // Set interval to fetch users, bot ping, and bot info every 2 seconds
        if (selectedChannel) {
            fetchUsersAndBotInfo(); // Initial fetch for selected channel
            const intervalId = setInterval(fetchUsersAndBotInfo, 2000); // Fetch every 2 seconds
    
            return () => clearInterval(intervalId); // Cleanup interval on unmount
        }
    }, [selectedChannel]);
    
    const handleDisconnect = () => {
        axios.post('http://51.158.60.90:65533/api/disconnect')
            .then(() => {
                console.log('Bot disconnected from voice channel');
                // Set bot latency to 0 after disconnect
                setBotInfo(prevState => ({
                    ...prevState,
                    latency: 0 // Set botPing to 0
                }));
            })
            .catch(error => console.error('Error disconnecting bot:', error));
    };

 const socketRef = useRef(null);

useEffect(() => {
  socketRef.current = new WebSocket('ws://51.158.60.90:4547');
  
  socketRef.current.onopen = () => {
    console.log('WebSocket connected');
  };

  socketRef.current.onclose = () => {
    console.log('WebSocket closed');
  };

  socketRef.current.onerror = (error) => {
    console.error('WebSocket error:', error);
  };

  // Cleanup on component unmount
  return () => {
    if (socketRef.current) {
      socketRef.current.close();
    }
  };
}, []);
const startAudioCapture = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const input = audioContext.createMediaStreamSource(stream);
  
      const processor = audioContext.createScriptProcessor(1024, 1, 1);
      processor.onaudioprocess = (event) => {
        const audioData = event.inputBuffer.getChannelData(0);
  
        // Send audio data via WebSocket
        if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
          socketRef.current.send(audioData);
        }
      };
  
      input.connect(processor);
      processor.connect(audioContext.destination);
  
    } catch (err) {
      console.error('Error capturing microphone:', err);
    }
  };
  
  const stopAudioCapture = () => {
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach(track => track.stop());
    }
    if (audioContextRef.current) {
      audioContextRef.current.close();
    }
    setMicMuted(true);
  };
  
  const toggleMic = () => {
    if (micMuted) {
      startAudioCapture();
    } else {
      stopAudioCapture();
    }
  };
  
  useEffect(() => {
    if (socketRef.current) {
      socketRef.current.onmessage = async (message) => {
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        
        // Ensure the message data is treated as an ArrayBuffer
        if (message.data instanceof ArrayBuffer) {
          try {
            const audioBuffer = await audioContext.decodeAudioData(message.data);
            
            const source = audioContext.createBufferSource();
            source.buffer = audioBuffer;
            source.connect(audioContext.destination);
            source.start(0);
          } catch (error) {
            console.error('Error decoding audio data:', error);
          }
        } else {
          console.error('Received data is not an ArrayBuffer');
        }
      };
    }
  }, []);
  

    // Toggle audio mute/unmute
    const toggleAudio = () => {
        setAudioMuted(!audioMuted);
        socketRef.current.emit('toggle-audio', { mute: !audioMuted });
    };


    
    const handleUserClick = (event, user) => {
        if (!user) {
            console.error('User is undefined or null:', user);
            return;
        }
        // Adjust for page scroll and container offset
        const xOffset = window.pageXOffset || document.documentElement.scrollLeft;
        const yOffset = window.pageYOffset || document.documentElement.scrollTop;
    
        setSelectedUser(user);
        setMenuPosition({
            x: event.clientX + xOffset,  // Add scroll offset to X
            y: event.clientY + yOffset   // Add scroll offset to Y
        });
        setMenuOpen(true);  // Manually open the menu
        console.log(`Menu open for user: ${user.username} at position: `, { x: event.clientX + xOffset, y: event.clientY + yOffset });
    };
    
    

    // Handle user action from UserMenu
    const handleUserAction = (action) => {
        console.log(`User action: ${action}`);
        setSelectedUser(null);
        setMenuOpen(false); // Close the menu after action
    };

    const getStatusIcon = (status) => {
        if (status.toLowerCase() === 'dnd') {
            return (
              <div style={{ position: 'relative', display: 'inline-block' }}>
                <FaCircle style={{ color: 'red', fontSize: '16px' }} />
                <div style={{
                  position: 'absolute',
                  top: '50%',
                  left: '25%',
                  width: '50%',
                  height: '2px',
                  backgroundColor: 'black',
                  transform: 'translateY(-50%)',
                }}></div>
              </div>
            );
        } else if (status.toLowerCase() === 'idle') {
            return (
              <div style={{ position: 'relative', display: 'inline-block' }}>
                <FaCircle style={{ color: 'yellow', fontSize: '16px' }} />
                <FaCircle style={{
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  color: 'white',
                  fontSize: '16px',
                  clipPath: 'circle(45% at 15% 10%)'  // Adjusted for less crescent and higher on the left
                }} />
              </div>
            );
        } else if (status.toLowerCase() === 'online') {
            return <FaCircle style={{ color: 'green', fontSize: '16px' }} />;
        } else if (status.toLowerCase() === 'offline') {
            return (
              <div style={{ position: 'relative', display: 'inline-block' }}>
                <FaCircle style={{ color: 'gray', fontSize: '16px' }} />
                <FaCircle style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  width: '8px',  // Smaller circle inside
                  height: '8px',
                  borderRadius: '50%',
                  backgroundColor: 'white',  // Transparent or white center for "offline"
                  transform: 'translate(-50%, -50%)',
                }} />
              </div>
            );
        } else {
            return <FaCircle style={{ color: getStatusColor(status), fontSize: '16px' }} />;
        }
    };
    
    return (
        <Flex direction="row" h="calc(90vh - 80px)" p={4} mt="60px" bg="transparent" borderRadius="md" overflow="hidden">
            <Box w="30%" p={4} bg="transparent" borderRadius="md" boxShadow="outline">
                <Heading as="h3" size="lg" mb={4}>Channels Vocal</Heading>
                <List spacing={3}>
                    {channels.map((channel) => (
                        <ListItem
                            key={channel.id}
                            p={2}
                            bg={selectedChannel === channel.id ? 'blue.700' : 'blue.300'}
                            _hover={{ bg: 'red.400', cursor: 'pointer' }}
                            onClick={() => handleChannelClick(channel.id)}
                        >
                            <Flex justify="space-between">
                                <Text fontWeight="bold">{channel.name}</Text>
                                <Text fontSize="sm" color="back.900">
                                    {channel.users ? channel.users.length : 0} Users
                                </Text>
                            </Flex>
                        </ListItem>
                    ))}
                </List>
            </Box>

            <Box w="70%" p={4} ml={4} bg="transparent" borderRadius="md" boxShadow="outline" position="relative">
    <Heading as="h3" size="lg" mb={4}>
        {selectedChannel ? `Utilisateurs connecté dans le Channel [${channels.find(c => c.id === selectedChannel)?.name}]` : 'Select a Channel'}
    </Heading>
    <Text mb={4} color="blue.500">
    {botInfo.username ? `${botInfo.username}` : '-'} Discord Latency: {botInfo.latency} ms
</Text>

    <List spacing={3}>
        {users.map(user => (
                <ListItem
                key={user.id}
                p={2}
                bg="gray.50"
                _hover={{ bg: 'blue.100', cursor: 'pointer' }}
                onClick={(e) => handleUserClick(e, user)}
              >
                <Flex align="center" justify="space-between">
                  <Flex align="center">
                    <Avatar size="sm" src={user.avatar} mr={3} />
                    <Text fontWeight="medium" color="black">{user.username}</Text>
                  </Flex>
                  
                    <Flex align="center">
                    <Flex align="center" mr={2}>
                            {getStatusIcon(user.status)}
                        </Flex>
                        {user.mute && (
                            <Flex align="center" mr={2}>
                                <FaMicrophoneSlash color="red" />
                                {/*<Text ml={1} color="black">Mic Muted</Text>*/}
                            </Flex>
                        )}
                        {user.deaf && (
                            <Flex align="center" mr={2}>
                                <TbHeadphonesOff color="red" />
                                {/*<Text ml={1} color="black">Casque Muted</Text>*/}
                            </Flex>
                        )}
                        {user.self_stream && (
                            <Flex align="center" mr={2}>
                                <MdOutlineLiveTv color="purple" />
                                {/*<Text ml={1} color="purple">Go Live</Text>*/}
                            </Flex>
                        )}
                        {user.self_video && (
                            <Flex align="center" mr={2}>
                                <FaVideo color="green" />
                                {/*<Text ml={1} color="black">Caméra Activée</Text>*/}
                            </Flex>
                        )}
                        {user.suppress && (
                            <Flex align="center" mr={2}>
                                <FaBan color="orange" />
                                {/*<Text ml={1} color="black">Permission Refusée</Text>*/}
                            </Flex>
                        )}
                    </Flex>
                </Flex>
                {menuOpen && selectedUser && (
                    <Box position="absolute" top={`${menuPosition.y}px`} left={`${menuPosition.x}px`} zIndex="1000">
                        <UserMenu
                            user={selectedUser}
                            onAction={handleUserAction}
                            onClose={() => setMenuOpen(false)}
                        />
            </Box>
                )}
            </ListItem>
        ))}
    </List>
                <Flex mt={4} align="center">
                    <IconButton disabled
                        icon={micMuted ? <FaMicrophoneSlash /> : <FaMicrophone />}
                        aria-label="Toggle Microphone"
                        onClick={toggleMic}
                        mr={2}
                    />
                    <IconButton disabled
                        icon={audioMuted ? <FaHeadphones /> : <TbHeadphonesOff />}
                        aria-label="Toggle Audio"
                        onClick={toggleAudio}
                        mr={2}
                    />
                </Flex>
                <Button mt={4}
                    colorScheme="purple"
                    onClick={() => {
                    axios.post('http://51.158.60.90:65533/api/play-radio', {
                    channelId: selectedChannel,
                    streamUrl: 'https://n43a-eu.rcs.revma.com/7081n2b6p98uv?rj-ttl=5&rj-tok=AAABkk9Vi-4A4wXn_C5FnXroLg'
                    })
                    .then(() => {
                    console.log('Playing RadioFG in the selected channel!');
                    })
                    .catch(error => {
                    console.error('Error starting the radio stream:', error);
                    });
                    }}
                > RadioFG
                </Button>

                <Button mt={4}
                    colorScheme="purple"
                    onClick={() => {
                    axios.post('http://51.158.60.90:65533/api/play-radio', {
                    channelId: selectedChannel,
                    streamUrl: 'https://25223.live.streamtheworld.com/Q_DANCE.mp3'
                    })
                    .then(() => {
                    console.log('Playing Q-Dance Radio in the selected channel!');
                    })
                    .catch(error => {
                    console.error('Error starting the radio stream:', error);
                    });
                    }}
                > QDance
                </Button>
                <Button mt={4} colorScheme="red" onClick={handleDisconnect}>Disconnect</Button>
                <Button mt={4} onClick={() => setShowLogs(!showLogs)}>
                {showLogs ? 'Close Debug Console' : 'Open Debug Console'}
                </Button>

                {showLogs && <LogsRTCBot />}
                {selectedUser && (
                    <UserMenu
                        user={selectedUser}
                        onAction={handleUserAction}
                    />
                )}
            </Box>
        </Flex>
    );
};

export default VoiceChannelList;
