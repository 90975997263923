import React, { useState } from 'react';
import axios from 'axios';
import { Box, Button, Input, FormLabel, FormControl, Select, Stack, useToast } from '@chakra-ui/react';

const CreateRoleForm = () => {
    const [guildId, setGuildId] = useState('');
    const [roleName, setRoleName] = useState('');
    const [color, setColor] = useState('');
    const [userIds, setUserIds] = useState('');
    const toast = useToast();

    const handleSubmit = async (e) => {
        e.preventDefault();

        const userIdArray = userIds.split(',').map(id => id.trim()); // Convertir les user IDs en tableau

        try {
            const response = await axios.post('https://peaky.bot.mrapi.net/api/roles', {
                guildId,
                roleName,
                color,
                userIds: userIdArray,
            });

            toast({
                title: 'Rôle créé',
                description: `Le rôle ${roleName} a été créé avec succès.`,
                status: 'success',
                duration: 5000,
                isClosable: true,
            });

            // Réinitialiser les champs après la création
            setGuildId('');
            setRoleName('');
            setColor('');
            setUserIds('');
        } catch (error) {
            console.error('Erreur lors de la création du rôle:', error);

            toast({
                title: 'Erreur',
                description: 'Une erreur est survenue lors de la création du rôle.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <Box p={4} bg="gray.100" borderRadius="md" maxWidth="500px" margin="auto">
            <form onSubmit={handleSubmit}>
                <Stack spacing={4}>
                    <FormControl id="guild-id" isRequired>
                        <FormLabel>ID du Serveur Discord</FormLabel>
                        <Input
                            type="text"
                            value={guildId}
                            onChange={(e) => setGuildId(e.target.value)}
                            placeholder="Entrez l'ID du serveur Discord"
                        />
                    </FormControl>

                    <FormControl id="role-name" isRequired>
                        <FormLabel>Nom du Rôle</FormLabel>
                        <Input
                            type="text"
                            value={roleName}
                            onChange={(e) => setRoleName(e.target.value)}
                            placeholder="Entrez le nom du rôle"
                        />
                    </FormControl>

                    <FormControl id="color">
                        <FormLabel>Couleur du Rôle</FormLabel>
                        <Input
                            type="color"
                            value={color}
                            onChange={(e) => setColor(e.target.value)}
                            placeholder="Choisissez la couleur du rôle (optionnel)"
                        />
                    </FormControl>

                    <FormControl id="user-ids">
                        <FormLabel>User IDs</FormLabel>
                        <Input
                            type="text"
                            value={userIds}
                            onChange={(e) => setUserIds(e.target.value)}
                            placeholder="Entrez les IDs des utilisateurs séparés par des virgules"
                        />
                    </FormControl>

                    <Button colorScheme="blue" type="submit">
                        Créer le Rôle et l'Attribuer
                    </Button>
                </Stack>
            </form>
        </Box>
    );
};

export default CreateRoleForm;
