import React, { useEffect, useState, useRef } from 'react';
import { Box, Button, Flex, Text, useColorModeValue, Input } from '@chakra-ui/react';
import io from 'socket.io-client';

const VoiceBotComponent = () => {
    const [logs, setLogs] = useState('');
    const [message, setMessage] = useState('');
    const logsEndRef = useRef(null);
    const socketRef = useRef(null);

    const backgroundColor = useColorModeValue('gray.800', 'gray.900');
    const textColor = useColorModeValue('green.300', 'green.500');

    useEffect(() => {
        // Connect to the bot via WebSocket
        socketRef.current = io('http://51.158.60.255:3001'); // Replace with your actual backend URL

        socketRef.current.on('connect', () => {
            console.log('Connected to the voice bot server');
        });

        socketRef.current.on('bot-voice-log', (data) => {
            setLogs((prevLogs) => prevLogs + '\n' + data);
        });

        socketRef.current.on('disconnect', () => {
            console.log('Disconnected from the voice bot server');
        });

        return () => {
            if (socketRef.current) {
                socketRef.current.disconnect();
            }
        };
    }, []);

    // Auto-scroll to the latest log entry
    useEffect(() => {
        if (logsEndRef.current) {
            logsEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [logs]);

    const sendMessage = () => {
        if (socketRef.current) {
            socketRef.current.emit('bot-voice-command', message);
            setMessage('');
        }
    };

    return (
        <Flex direction="column" h="calc(100vh - 60px)" p={4} mt="60px" bg="transparent" borderRadius="md" overflow="hidden">
            <Box
                bg={backgroundColor}
                color={textColor}
                borderRadius="md"
                p={4}
                height="calc(100vh - 160px)" // Dynamically adjust based on the screen size
                overflowY="scroll"
                fontFamily="monospace"
                whiteSpace="pre-wrap"
                boxShadow="lg"
            >
                <Text>{logs}</Text>
                <div ref={logsEndRef} />
            </Box>
            <Flex mt={4}>
                <Input
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Type a message to the voice bot"
                    mr={2}
                    color={textColor}
                />
                <Button colorScheme="blue" onClick={sendMessage}>
                    Send
                </Button>
            </Flex>
        </Flex>
    );
};

export default VoiceBotComponent;
