import React, { useEffect, useState, useRef } from 'react';
import { Box, Flex, Text, Button, useColorModeValue, useToast } from '@chakra-ui/react';

const LogsRTCBot = () => {
    const [logs, setLogs] = useState('');
    const logsEndRef = useRef(null);
    const logsContainerRef = useRef(null);
    const toast = useToast();

    const backgroundColor = useColorModeValue('gray.800', 'gray.900');
    const textColor = useColorModeValue('green.300', 'green.500');

    useEffect(() => {
        const socket = new WebSocket('ws://51.158.60.255:4547/');

        socket.onmessage = (event) => {
            setLogs((prevLogs) => prevLogs + '\n' + event.data);
        };

        socket.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        return () => socket.close();
    }, []);

    useEffect(() => {
        if (logsEndRef.current && logsContainerRef.current) {
            logsContainerRef.current.scrollTop = logsContainerRef.current.scrollHeight;
        }
    }, [logs]);

    const handleCaptureLogs = () => {
        const logsArray = logs.split('\n');
        const lastTenLogs = logsArray.slice(-10).join('\n');

        navigator.clipboard.writeText(lastTenLogs).then(() => {
            toast({
                title: 'Logs copied!',
                description: 'The last 10 lines of logs have been copied to the clipboard.',
                status: 'success',
                duration: 3000,
                isClosable: true,
                position: 'bottom-right',
                variant: 'outline',
                colorScheme: 'blue',
            });
        }).catch(() => {
            toast({
                title: 'Failed to copy logs.',
                description: 'There was an issue copying the logs to the clipboard.',
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'bottom-right',
            });
        });
    };

        // Function to render logs with color formatting for specific parts
        const renderLogs = (logData) => {
            return logData.split('\n').map((line, index) => {
                const dateMatch = line.match(/^(\w{3} \d{2} \d{2}:\d{2}:\d{2})/); // Match the date
                const restOfLine = dateMatch ? line.replace(dateMatch[0], '') : line;
    
                const isTwitchLog = restOfLine.includes('Pas de changement dans le statut du stream.');
                const isConneted = restOfLine.includes('Client Connecté');
                const isDisconneted = restOfLine.includes('Client Déconnecté');
                const system = restOfLine.includes('sd-175382 systemd[1]');
                const SendEmbedMessageAPI = restOfLine.includes('Notification envoyée au canal Discord avec succès.');
                const API = restOfLine.includes('Données envoyées à l\'API');
    
                const last_touched_by = restOfLine.includes("last_touched_by");
                const item = restOfLine.includes("item");
                const Quantité = restOfLine.includes("Quantité");
                const status = restOfLine.includes("status");
                const timestamp = restOfLine.includes("timestamp");
                const Quantité_AS = restOfLine.includes("Quantité_AS");
                const Quantité_AP = restOfLine.includes("Quantité_AP");
    
                const SWEBBOT = restOfLine.includes('Server running on port 65534');
                const LoginBot = restOfLine.includes('Logged in as Peaky Blinders#8970!');
                const ClientBot = restOfLine.includes('CLIENT_ID: 1274171415754444830');
                const GuildServer = restOfLine.includes('GUILD_ID: 1241867949862555729');
                const RCommands = restOfLine.includes('Started refreshing application (/) commands.');
                const SCommands = restOfLine.includes('Successfully reloaded application (/) commands.');
                const guildMemberAdd = restOfLine.includes('Membre ajouté à la base de données avec l\'ID');
                const Error = restOfLine.includes("/var/www/PeakyBlinders/API/DiscordBot/PeakyBot.js");
                const Error0 = restOfLine.includes("/var/www/PeakyBlinders/API/DiscordBot/node_modules/");
                const Error1 = restOfLine.includes("processMessageQueue();");
                const Error2 = restOfLine.includes("^");
                const Error3 = restOfLine.includes("ReferenceError");
                const Error4 = restOfLine.includes("at Client.emit (node:events:526:35)");
                const NodeVersion = restOfLine.includes("Node.js v20.5.0");
                const SFTP = restOfLine.includes("Données écrites dans le fichier api_data_sent.txt avec succès.");
                const sql = restOfLine.includes("mises à jour");
                const sql1 = restOfLine.includes("mis à jour");
    
                const WelcomeMembers = restOfLine.includes("Message de bienvenue");
                const RolesAdd = restOfLine.includes("Rôle");
                const DM = restOfLine.includes("Message privé");
                const DM1 = restOfLine.includes("DM reçu de: Peaky Blinders");
                const DM2 = restOfLine.includes("Contenu du DM:");
                const table = restOfLine.includes("actuelle");
                const plusmoin = restOfLine.includes("Déposant/Soustrayant");
                const treads = restOfLine.includes("Le Channel");
    
    
                const ProcessingMessages = restOfLine.includes("Processing message:");
                const PMessages = restOfLine.includes("Message reçu :");
                const DMessages = restOfLine.includes("Message received:");
                const EMessages = restOfLine.includes("Embed received:");
                const DEMessages = restOfLine.includes("Embed received in DM:");
                const DEMessages2 = restOfLine.includes("DM received from:");
    
                return (
                    <Text key={index} color={textColor} fontFamily="monospace" whiteSpace="pre-wrap">
                        {dateMatch && <Text as="span" color="orange.400">{dateMatch[0]} </Text>}
                        {isTwitchLog && <Text as="span" color="purple.400">[TWITCH]</Text>}
                        {isConneted && <Text as="span" color="green.400">[WS]</Text>}
                        {isDisconneted && <Text as="span" color="red.400">[WS]</Text>}
                        {system && <Text as="span" color="red.400">[SYSTEM]</Text>}
                        {SendEmbedMessageAPI && <Text as="span" color="red.400">[DISCORD]</Text>}
                        {SWEBBOT && <Text as="span" color="orange.500">[WEB SERVER API]</Text>}
                        {LoginBot && <Text as="span" color="red.400">[DISCORD]</Text>}
                        {ClientBot && <Text as="span" color="red.400">[DISCORD]</Text>}
                        {GuildServer && <Text as="span" color="red.400">[DISCORD]</Text>}
                        {RCommands && <Text as="span" color="red.400">[DISCORD]</Text>}
                        {SCommands && <Text as="span" color="red.400">[DISCORD]</Text>}
                        {guildMemberAdd && <Text as="span" color="red.400">[API]</Text>}
                        {Error && <Text as="span" color="red.500">[ERROR]</Text>}
                        {Error0 && <Text as="span" color="red.500">[ERROR]</Text>}
                        {Error1 && <Text as="span" color="red.500">[ERROR]</Text>}
                        {Error2 && <Text as="span" color="red.500">[ERROR]</Text>}
                        {Error3 && <Text as="span" color="red.500">[ERROR]</Text>}
                        {Error4 && <Text as="span" color="red.500">[ERROR]</Text>}
                        {NodeVersion && <Text as="span" color="yellow.600">[NodeJS]</Text>}
                        {SFTP && <Text as="span" color="yellow.300">[SFTP]</Text>}
                        {sql && <Text as="span" color="yellow.300">[SQL]</Text>}
                        {sql1 && <Text as="span" color="yellow.300">[SQL]</Text>}
                        {API && <Text as="span" color="red.400">[API]</Text>}
                        {ProcessingMessages && <Text as="span" color="teal.400">[MESSAGE DISCORD]</Text>}
                        {PMessages && <Text as="span" color="teal.400">[MESSAGE DISCORD]</Text>}
                        {DMessages && <Text as="span" color="teal.400">[MESSAGE DISCORD]</Text>}
                        {EMessages && <Text as="span" color="teal.400">[MESSAGE DISCORD]</Text>}
                        {DEMessages && <Text as="span" color="blue.400">[MESSAGE PRIVE DISCORD]</Text>}
                        {WelcomeMembers && <Text as="span" color="purple.500">[WELCOME MEMBRES GUILD]</Text>}
                        {RolesAdd && <Text as="span" color="purple.500">[ADD ROLES DISCORD]</Text>}
                        {DM && <Text as="span" color="blue.400">[MESSAGE PRIVE DISCORD]</Text>}
                        {DM1 && <Text as="span" color="blue.400">[MESSAGE PRIVE DISCORD]</Text>}
                        {DM2 && <Text as="span" color="blue.400">[MESSAGE PRIVE DISCORD]</Text>}
                        {DEMessages2 && <Text as="span" color="blue.400">[MESSAGE PRIVE DISCORD]</Text>}
                        {table && <Text as="span" color="red.400">[API]</Text>}
                        {plusmoin && <Text as="span" color="red.400">[API]</Text>}
                        {treads && <Text as="span" color="red.400">[DISCORD]</Text>}
    
                        {restOfLine.replace(/sd-175382 node\[\d+\]/, '')}
                    </Text>
                );
            });
        };
    
        return (
            <Flex direction="column" h="calc(100vh - 60px)" p={4} mt="60px" bg="transparent" borderRadius="md" overflow="hidden">
                <Box
                    ref={logsContainerRef}
                    bg={backgroundColor}
                    color={textColor}
                    borderRadius="md"
                    p={4}
                    height="calc(100vh - 120px)" // Dynamically adjust based on the screen size
                    overflowY="scroll"
                    fontFamily="monospace"
                    whiteSpace="pre-wrap"
                    boxShadow="lg"
                    position="relative" // Enable positioning for child elements
                >
                    {renderLogs(logs)}
                    <div ref={logsEndRef} />
    
                    {/* Capture Button */}
                    <Button
                        onClick={handleCaptureLogs}
                        colorScheme="red"
                        variant="outline"
                        size="sm"
                        position="fixed" // Make the button fixed relative to the container
                        bottom="800px" // Always stay at the bottom
                        right="60px" // Positioned at the bottom-right corner
                        zIndex={10} // Ensure it stays above the logs
                    >
                        Capture
                    </Button>
                </Box>
            </Flex>
        );
    };
    
    export default LogsRTCBot;    