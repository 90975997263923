import React, { useEffect, useState } from 'react';
import { SimpleGrid, Box, Text, Image, Spinner, Center, Flex } from '@chakra-ui/react';
import axios from 'axios';

const ArgentPerUser = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [lastUpdate, setLastUpdate] = useState(null);

    const fetchUsers = async () => {
        try {
            const response = await axios.get('https://peaky.api.mrapi.net/allusers');
            setUsers(response.data);
            setLoading(false);
            setLastUpdate(new Date());
        } catch (error) {
            console.error('Erreur lors de la récupération des données:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUsers(); // Initial fetch
        const interval = setInterval(fetchUsers, 10000); // Fetch every 10 seconds

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, []);

    if (loading) {
        return (
            <Center h="100vh">
                <Spinner size="xl" />
            </Center>
        );
    }

    return (
        <Flex flexDirection='column' pt={{ base: "120px", md: "75px" }}>
            <Flex justifyContent="flex-end" mb="10px">
                {lastUpdate && (
                    <Text fontSize="sm" color="gray.500">
                        Mise à jour à : {lastUpdate.toLocaleTimeString()}
                    </Text>
                )}
            </Flex>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing='20px'>
                {users.map((user) => (
                    <Box
                        key={user.id}
                        borderWidth='1px'
                        borderRadius='lg'
                        overflow='hidden'
                        boxShadow='sm'
                        width={{ base: '100%', md: '80%', lg: '60%' }}
                        mx='auto'
                        position='relative'
                    >
                        <Image
                            src={user.avatars}
                            alt={`${user.firstname} ${user.lastname}`}
                            width='100%'
                            height='auto'
                            objectFit='cover'
                        />
                        <Box p="6">
                            <Box display="flex" alignItems="baseline">
                                <Text
                                    fontWeight="bold"
                                    fontSize="lg"
                                    textTransform="uppercase"
                                    ml="2"
                                    color="gray.600"
                                >
                                    {user.firstname} {user.lastname}
                                </Text>
                            </Box>
                            <Box mt={4}>
                                <Text fontSize="md" mt={2}>
                                    Compte Bancaire: {user.Bank !== null ? `${user.Bank}` : 'N/A'}
                                </Text>
                                <Text fontSize="md" mt={2}>
                                    N° Téléphone: {user.phoneNumber || 'N/A'}
                                </Text>
                            </Box>
                        </Box>
                    </Box>
                ))}
            </SimpleGrid>
        </Flex>
    );
};

export default ArgentPerUser;
