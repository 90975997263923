// routes.js
import Dashboard1 from "views/Dashboard/Dashboard/index2.js";
import CoffreEntrepot from "views/Dashboard/Entrepot";
import Tables from "views/Dashboard/Tables";
import Profile from "views/Dashboard/Profile";
import SignIn from "views/Auth/SignIn.js";
import SignUp from "views/Auth/SignUp.js";

//Custom
import UsersList from "views/Admin/Users/list";
import EditUser from "views/Admin/Users/edit";
import CreateUser from "views/Admin/Users/create";
import ArgentPerUser from "views/Dashboard/Comptabilite/argentperusers";

import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  RocketIcon,
} from "components/Icons/Icons";
import { FaDiscord } from "react-icons/fa";
import { FaRegUser } from "react-icons/fa";
import { MdOutlineDashboard } from "react-icons/md";
import { MdOutlineInventory2 } from "react-icons/md";
import QuotaPage from "views/Dashboard/Works/qoata";
import DiscordApp from "views/Admin/Discord";
import DMList from "views/Admin/Discord/DmMessage";
import VenteDrogue from "views/Dashboard/Comptabilite/VenteDrogue";
import CoffreBouffe from "views/Dashboard/Entrepot/CoffreBouffe";
import CoffreDrogue from "views/Dashboard/Entrepot/CoffreDrogue";
import CoffreLabo from "views/Dashboard/Entrepot/CoffreLabo";
import DiscordGuildLogMembers from "views/Admin/Discord/Guild/log";
import MemberCardlog from "views/Admin/Discord/Guild/log";
import TicketMessenger from "views/Admin/Discord/Tickets/Messenger";
import CreateRoleForm from "views/Admin/Discord/Guild/roles";
import CoffreLabo2 from "views/Dashboard/Entrepot/CoffreLabo2";
import CLabo from "views/Dashboard/Entrepot/CLabo";
import Branche from "views/Dashboard/Entrepot/CoffreBranche";
import CoffreDrogue2 from "views/Dashboard/Entrepot/CoffreDrogue2";
import CoffreDrogue3 from "views/Dashboard/Entrepot/CoffreDrogue3";
import CLabo2 from "views/Dashboard/Entrepot/CLabo2";
import BotLogsConsole from "views/Admin/Discord/Logs/logs";
import VoiceBotComponent from "views/Admin/Discord/Voices/connect";
import VoiceChannelList from "views/Admin/Discord/Voices/list";
import AdminReports from "views/Admin/Discord/Logs/report";

// Define routes with requiredGrades and hidden properties
const dashRoutes = [
  {
    path: "/!/Application/-/0/-/1274171415754444830/vocal",
    name: "Vocal",
    icon: <FaDiscord color="inherit" />,
    component: VoiceChannelList,
    layout: "/admin",
    requiredGrades: [255],
    hidden: false,
  },
  {
    path: "/!/Application/-/255/-/report",
    name: "Report/FeedBack",
    icon: <FaDiscord color="inherit" />,
    component: AdminReports,
    layout: "/admin",
    requiredGrades: [255],
    hidden: false,
  },
  {
    path: "/!/Application/-/0/-/1274171415754444830/vocal-active",
    name: "Vocal PeakyBlinders#8970",
    icon: <FaDiscord color="inherit" />,
    component: VoiceBotComponent,
    layout: "/admin",
    requiredGrades: [255],
    hidden: true,
  },
  {
    path: "/!/Application/-/0/-/1274171415754444830/console",
    name: "Logs PeakyBlinders#8970",
    icon: <FaDiscord color="inherit" />,
    component: BotLogsConsole,
    layout: "/admin",
    requiredGrades: [95, 100, 255],
    hidden: false,
  },
  {
    path: "/!/Discord/Application/1274171415754444830",
    name: "DiscordBot - Peaky Blinders",
    icon: <FaDiscord color="inherit" />,
    component: DiscordApp,
    layout: "/admin",
    requiredGrades: [95, 255],
    hidden: false,
  },
  {
    path: "/!/Discord/Application/dm/list",
    name: "DiscordBot - Peaky Blinders",
    icon: <FaDiscord color="inherit" />,
    component: DMList,
    layout: "/admin",
    requiredGrades: [255],
    hidden: true,
  },
  {
    path: "/!/Discord/Guild/1241867949862555729",
    name: "Discord Guild - Peaky Blinders",
    icon: <FaDiscord color="inherit" />,
    component: MemberCardlog,
    layout: "/admin",
    requiredGrades: [255],
    hidden: false,
  },
  {
    path: "/!/Discord/Tickets/Guild/1241867949862555729",
    name: "Discord Tickets - Peaky Blinders",
    icon: <FaDiscord color="inherit" />,
    component: TicketMessenger,
    layout: "/admin",
    requiredGrades: [255],
    hidden: false,
  },
  {
    path: "/!/Discord/Roles/Guild/1241867949862555729",
    name: "Discord Roles - Peaky Blinders",
    icon: <FaDiscord color="inherit" />,
    component: CreateRoleForm,
    layout: "/admin",
    requiredGrades: [255],
    hidden: false,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: <MdOutlineDashboard color="inherit" />,
    component: Dashboard1,
    layout: "/admin",
    requiredGrades: [0, 40, 50, 85, 90, 94, 95, 100, 255],
    hidden: false,
  },
  {
    path: "/Entrepot/Coffre",
    name: "Coffre Entrepot",
    icon: <MdOutlineInventory2 color="inherit" />,
    component: CoffreEntrepot,
    layout: "/admin",
    requiredGrades: [40, 50, 85, 90, 94, 95, 100, 255],
    hidden: false,
  },
  {
    path: "/Entrepot/Bouffe/Coffre",
    name: "Coffre Entrepot",
    icon: <MdOutlineInventory2 color="inherit" />,
    component: CoffreBouffe,
    layout: "/admin",
    requiredGrades: [40, 50, 85, 90, 94, 95, 100, 255],
    hidden: true,
  },
  {
    path: "/Entrepot/Drogue/Coffre",
    name: "Coffre Entrepot",
    icon: <MdOutlineInventory2 color="inherit" />,
    component: CoffreDrogue,
    layout: "/admin",
    requiredGrades: [40, 50, 85, 90, 94, 95, 100, 255],
    hidden: true,
  },
  {
    path: "/Entrepot/Drogue2/Coffre",
    name: "Coffre Entrepot",
    icon: <MdOutlineInventory2 color="inherit" />,
    component: CoffreDrogue2,
    layout: "/admin",
    requiredGrades: [40, 50, 85, 90, 94, 95, 100, 255],
    hidden: true,
  },
  {
    path: "/Entrepot/Drogue3/Coffre",
    name: "Coffre Entrepot",
    icon: <MdOutlineInventory2 color="inherit" />,
    component: CoffreDrogue3,
    layout: "/admin",
    requiredGrades: [40, 50, 85, 90, 94, 95, 100, 255],
    hidden: true,
  },
  {
    path: "/Entrepot/Labo/Coffre",
    name: "Coffre Entrepot",
    icon: <MdOutlineInventory2 color="inherit" />,
    component: CoffreLabo,
    layout: "/admin",
    requiredGrades: [40, 50, 85, 90, 94, 95, 100, 255],
    hidden: true,
  },
  {
    path: "/Entrepot/Labo2/Coffre",
    name: "Coffre Entrepot",
    icon: <MdOutlineInventory2 color="inherit" />,
    component: CoffreLabo2,
    layout: "/admin",
    requiredGrades: [40, 50, 85, 90, 94, 95, 100, 255],
    hidden: true,
  },
  {
    path: "/Entrepot/CLabo/Coffre",
    name: "Coffre Entrepot",
    icon: <MdOutlineInventory2 color="inherit" />,
    component: CLabo,
    layout: "/admin",
    requiredGrades: [40, 50, 85, 90, 94, 95, 100, 255],
    hidden: true,
  },
  {
    path: "/Entrepot/CLabo2/Coffre",
    name: "Coffre Entrepot",
    icon: <MdOutlineInventory2 color="inherit" />,
    component: CLabo2,
    layout: "/admin",
    requiredGrades: [40, 50, 85, 90, 94, 95, 100, 255],
    hidden: true,
  },
  {
    path: "/Entrepot/Branche/Coffre",
    name: "Coffre Entrepot",
    icon: <MdOutlineInventory2 color="inherit" />,
    component: Branche,
    layout: "/admin",
    requiredGrades: [40, 50, 85, 90, 94, 95, 100, 255],
    hidden: true,
  },
  {
    path: "/Works/Quota",
    name: "Works",
    icon: <HomeIcon color="inherit" />,
    component: QuotaPage,
    layout: "/admin",
    requiredGrades: [255],
    hidden: false,
  },
  {
    path: "/Works/VenteD",
    name: "VenteD",
    icon: <HomeIcon color="inherit" />,
    component: VenteDrogue,
    layout: "/admin",
    requiredGrades: [255],
    hidden: false,
  },
  {
    path: "/Works/PKB/User",
    name: "Compta Users PKB",
    icon: <HomeIcon color="inherit" />,
    component: ArgentPerUser,
    layout: "/admin",
    requiredGrades: [255],
    hidden: false,
  },
  {
    path: "/Users",
    name: "Users Administration",
    icon: <FaRegUser color="inherit" />,
    component: UsersList,
    layout: "/admin",
    requiredGrades: [94, 95, 100, 255],
    hidden: false,
  },
  {
    path: "/Users/Create",
    name: "Create User",
    component: CreateUser,
    layout: "/admin",
    requiredGrades: [100, 255],
    hidden: true,
  },
  {
    path: "/Users/Edit/:id",
    name: "Edit User",
    component: EditUser,
    layout: "/admin",
    requiredGrades: [100, 255],
    hidden: true,
  },
  {
    path: "/profile",
    name: "Profile",
    icon: <PersonIcon color="inherit" />,
    secondaryNavbar: true,
    component: Profile,
    layout: "/admin",
    hidden: true,
    requiredGrades: [40, 50, 85, 90, 94, 95, 100, 255],
  },
  {
    path: "/login",
    component: SignIn,
    layout: "/auth",
    hidden: true,
  },
  {
    path: "/register",
    name: "Sign Up",
    icon: <RocketIcon color="inherit" />,
    secondaryNavbar: true,
    component: SignUp,
    layout: "/auth",
    hidden: true,
  },
];

export default dashRoutes;
