import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Flex, Text, VStack, Button, List, ListItem, Input } from '@chakra-ui/react';
import { useAuth } from 'context/AuthContext';

const TICKETS_API_URL = 'https://peaky.api.mrapi.net/api/tickets';
const SEND_MESSAGE_API_URL = 'https://peaky.bot.mrapi.net/api/ticket/send-message';
const USER_API_URL = 'https://peaky.api.mrapi.net/user';

const TicketMessenger = () => {
    const [tickets, setTickets] = useState({});
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [lastUpdate, setLastUpdate] = useState(null);
    const [messageContent, setMessageContent] = useState('');
    const [userInfo, setUserInfo] = useState({ name: '', user_id: '' });
    const { token } = useAuth();

    useEffect(() => {
        const fetchTickets = async () => {
            try {
                const response = await axios.get(TICKETS_API_URL);
                const organizedTickets = response.data.reduce((acc, message) => {
                    if (!acc[message.channel_name]) {
                        acc[message.channel_name] = [];
                    }
                    acc[message.channel_name].push(message);
                    return acc;
                }, {});
                setTickets(organizedTickets);
                setLastUpdate(new Date());
            } catch (error) {
                console.error('Erreur lors de la récupération des tickets :', error);
            }
        };
        fetchTickets();
        const intervalId = setInterval(fetchTickets, 10000);
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        const fetchUserInfo = async () => {
            if (!token) return;
            try {
                const response = await axios.get(USER_API_URL, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setUserInfo({ name: response.data.name, user_id: response.data.user_id });
            } catch (error) {
                console.error('Erreur lors de la récupération des informations utilisateur :', error);
            }
        };
        fetchUserInfo();
    }, [token]);

    const handleSendMessage = async () => {
        if (!messageContent || !selectedTicket || !userInfo.user_id) return;

        try {
            await axios.post(SEND_MESSAGE_API_URL, {
                channel_name: selectedTicket,
                username: userInfo.name,
                user_id: userInfo.user_id,
                message_content: messageContent
            });

            setMessageContent('');

            const updatedTickets = { ...tickets };
            updatedTickets[selectedTicket].push({
                channel_name: selectedTicket,
                username: userInfo.name,
                user_id: userInfo.user_id,
                message_content: messageContent,
                message_date: new Date().toISOString()
            });
            setTickets(updatedTickets);
        } catch (error) {
            console.error('Erreur lors de l\'envoi du message :', error);
        }
    };

    return (
        <Flex direction="row" h="calc(90vh - 80px)" p={4} mt="60px" bg="transparent" borderRadius="md" overflow="hidden">
            <Box w="30%" bg="transparent" p={4} borderRight="1px solid #e0e0e0" overflowY="auto">
                <Text fontSize="xl" mb={4} fontWeight="bold">Tickets Discord</Text>
                <List spacing={3}>
                    {Object.keys(tickets).map(channelName => (
                        <ListItem 
                            key={channelName} 
                            onClick={() => setSelectedTicket(channelName)}
                            cursor="pointer"
                            p={2}
                            bg={selectedTicket === channelName ? 'transparent' : 'transparent'}
                            borderRadius="md"
                            _hover={{ bg: 'transparent' }}
                        >
                            {channelName}
                        </ListItem>
                    ))}
                </List>
            </Box>
            <Flex direction="column" w="70%" bg="transparent" p={4} position="relative">
                {selectedTicket ? (
                    <>
                        <Text fontSize="xl" mb={4} fontWeight="bold">
                            {selectedTicket}
                        </Text>
                        <VStack spacing={4} align="stretch" overflowY="auto" flex="1" mb="60px">
                            {tickets[selectedTicket]?.map((message, index) => (
                                <Flex 
                                    key={index} 
                                    direction="column" 
                                    alignSelf={message.user_id === userInfo.user_id ? 'flex-end' : 'flex-start'}
                                    maxWidth="80%"
                                >
                                    <Box bg={message.user_id === userInfo.user_id ? 'red.500' : 'blue.300'} color="white" borderRadius="md" p={2}>
                                        <Text color="black" fontWeight="bold">{message.username}</Text>
                                        <Text color="black">{message.message_content}</Text>
                                        <Text fontSize="xs" color="black" textAlign="right">
                                            {new Date(message.message_date).toLocaleTimeString()}
                                        </Text>
                                    </Box>
                                </Flex>
                            ))}
                        </VStack>
                        <Flex position="absolute" bottom="0" left="0" right="0" bg="transparent" p={4} borderTop="1px solid #e0e0e0">
                            <Input
                                value={messageContent}
                                onChange={(e) => setMessageContent(e.target.value)}
                                placeholder="Tapez votre message..."
                                bg="transparent"
                                borderRadius="md"
                                mr={2}
                            />
                            <Button onClick={handleSendMessage} colorScheme="blue">
                                Envoyer
                            </Button>
                        </Flex>
                    </>
                ) : (
                    <Text fontSize="lg" color="gray.500">
                        Sélectionnez un ticket pour voir les messages.
                    </Text>
                )}
            </Flex>
        </Flex>
    );
};

export default TicketMessenger;
