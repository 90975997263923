import React from 'react';
import { Box, Text, Flex, useColorModeValue } from '@chakra-ui/react';

const DrugCard = ({ drugName, quantity, membersSold }) => {
  const cardBackground = useColorModeValue('white', 'gray.700');
  const textColor = useColorModeValue('gray.700', 'white');

  return (
    <Box
      p={4}
      bg={cardBackground}
      shadow='md'
      borderRadius='lg'
      mb={4}
    >
      <Text fontSize='lg' fontWeight='bold' color={textColor}>
        {drugName}
      </Text>
      <Text fontSize='md' color={textColor}>
        Quantité disponible: {quantity}
      </Text>
      <Text fontSize='md' color={textColor}>
        Membres ayant vendu: {membersSold}
      </Text>
    </Box>
  );
};

export default DrugCard;
