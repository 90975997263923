import React, { useState } from 'react';
import { Box, Avatar, Text, Flex, List, ListItem, Button, useDisclosure, Checkbox } from '@chakra-ui/react';
import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/menu';

const UserMenu = ({ user, onAction }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isMuted, setIsMuted] = useState(false);
    const [isDeafened, setIsDeafened] = useState(false);

    return (
        <Box>
            {/* Wrap ListItem inside a List */}
            <List spacing={3}>
                <ListItem p={2} bg="red.50" _hover={{ bg: 'red.100' }} cursor="pointer" onClick={onOpen}>
                    <Flex align="center">
                        <Avatar size="sm" src={user.avatar} mr={3} />
                        <Text fontWeight="medium">{user.username}</Text>
                    </Flex>
                </ListItem>
            </List>

            {isOpen && (
                <Menu isOpen={isOpen} onClose={onClose}>
                    <MenuButton as={Box} position="absolute" zIndex="1000" bg="white" boxShadow="lg">
                        <MenuList>
                            <MenuItem onClick={() => onAction('message')} color="green.500">💬 Envoyé un Message Privé</MenuItem>
                            <MenuItem onClick={() => onAction('change_nick')} color="green.500">✏️ Changer le Pseudo</MenuItem>
                            <MenuItem>
                                <Checkbox 
                                    isChecked={isMuted} 
                                    onChange={(e) => { setIsMuted(e.target.checked); onAction('mute', e.target.checked); }} 
                                    colorScheme="orange">
                                    🔇 Rendre Muet (Server)
                                </Checkbox>
                            </MenuItem>
                            <MenuItem>
                                <Checkbox 
                                    isChecked={isDeafened} 
                                    onChange={(e) => { setIsDeafened(e.target.checked); onAction('deafen', e.target.checked); }} 
                                    colorScheme="orange">
                                    🔈 Mettre Sourdine (Server)
                                </Checkbox>
                            </MenuItem>
                            <MenuItem onClick={() => onAction('disconnect')} color="orange.500">🔌 Déconnecter</MenuItem>
                            <MenuItem onClick={() => onAction('kick')} color="red.500">❌ Expulser</MenuItem>
                            <MenuItem onClick={() => onAction('ban')} color="red.500">🚫 Bannir</MenuItem>
                        </MenuList>
                    </MenuButton>
                </Menu>
            )}
        </Box>
    );
};

export default UserMenu;
