// roles.js
export const roles = {
    Développeur: {
        grade: 255,
        canAccessDashboard: true,
        canManageUsers: true,
        canViewReports: true,
    },
    Admin: {
      grade: 100,
      canAccessDashboard: true,
      canManageUsers: true,
      canViewReports: true,
    },
    Chef: {
      grade: 95,
      canAccessDashboard: true,
      canManageUsers: true,
      canViewReports: true,
    },
    Patron: {
        grade: 94,
        canAccessDashboard: true,
        canManageUsers: true,
        canViewReports: true,
      },
    Conseiller: {
      grade: 90,
      canAccessDashboard: true,
      canManageUsers: true,
      canViewReports: true,
    },
    Capitaine: {
      grade: 85,
      canAccessDashboard: true,
      canManageUsers: true,
      canViewReports: true,
    },
    Membre: {
      grade: 50,
      canAccessDashboard: true,
      canManageUsers: false,
      canViewReports: true,
    },
    Soldat: {
      grade: 40,
      canAccessDashboard: true,
      canManageUsers: false,
      canViewReports: false,
    },
    Recrue: {
      grade: 1,
      canAccessDashboard: false,
      canManageUsers: false,
      canViewReports: false,
    },
    En_Attente: {
      grade: 0,
      canAccessDashboard: false,
      canManageUsers: false,
      canViewReports: false,
    },
  };

export const getGradeNumber = (roleName) => {
  return roles[roleName] ? roles[roleName].grade : null;
};
export const getRoleName = (gradeNumber) => {
  for (const [roleName, role] of Object.entries(roles)) {
    if (role.grade === gradeNumber) {
      return roleName;
    }
  }
  return null;
};
  